<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        In 1985, as a graduate student working with Gerard Mourou, Donna Strickland published a
        paper with Mourou describing a new method for creating very short intense laser pulses, a
        technique that is so widely used that they were recognized with the 2018 Nobel Prize in
        Physics. The laser used in this work was a Nd:YAG laser that emits at
        <stemble-latex content="$1064\,\text{nm,}$" />
        and the pulse that they generated delivered
        <stemble-latex content="$1.00\,\text{mJ}$" />
        in 2 ps. How many photons were contained in one of these compressed laser pulses?
      </p>

      <calculation-input
        v-model="inputs.numPhotons"
        prepend-text="$\text{Amount}:$"
        append-text="$\text{photons}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'Question477',
  components: {
    ChemicalLatex,
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        numPhotons: null,
      },
    };
  },
};
</script>
